



































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-view-model';

@Component({ name: 'EditEmergencyFund' })
export default class EditEmergencyFund extends Vue {
  edit_emergency_fund_view_model = Vue.observable(
    new EditEmergencyFundViewModel(this),
  );

  mounted() {
    this.edit_emergency_fund_view_model.initialize();
  }
}
