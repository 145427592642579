import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import Vue from 'vue';

// Application
import GetEmergencyFundInvestorGoalQuery
  from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import EditEmergencyFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-plan-service';

// Domain
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/current-plan-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class EditEmergencyFundViewModel {
  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditEmergencyFundGoalPlanCurrentPlanService;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-emergency-fund-goal-plan.edit_emergency_fund';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      value: '',
    },
    {
      label: this.translate('current_amount'),
      value: '',
    },
    {
      label: this.translate('monthly_contribution'),
      value: '',
    },
    {
      label: this.translate('target_date'),
      value: '',
    },
  ]

  associated_product_id = '';

  emergency_fund_id = '';

  initialize = async () => {
    await this.loadEmergencyFundInvestorGoalInformation();
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  loadEmergencyFundInvestorGoalInformation = async () => {
    try {
      const { investor_goal, id } = await this.get_emergency_fund_goal_query.execute();
      this.plan_information[0].value = this.getAmountFormatted(investor_goal.accumulated_amount);
      this.plan_information[1].value = this
        .getAmountFormatted(investor_goal.active_goal_amount || 0);
      this.plan_information[2].value = this
        .getAmountFormatted(investor_goal.monthly_required_amount);
      this.plan_information[3].value = this.date_formatter.formatDate(investor_goal.final_investment_date, 'DD/MM/YYYY');
      this.associated_product_id = investor_goal.associated_product_id;
      this.emergency_fund_id = id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_emergency_fund_information'));
    }
  }

  setCurrentPlantInformation = (current_plan_service: CurrentPlanEntity) => {
    this.current_plan_service.setCurrentPlanInformation(current_plan_service);
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }

  nextStep = () => {
    const current_plan: CurrentPlanEntity = {
      target_amount: this.plan_information[0].value,
      current_amount: this.plan_information[1].value,
      monthly_required_amount: this.plan_information[2].value,
      final_investment_date: this.plan_information[3].value,
      associated_product_id: this.associated_product_id,
      emergency_fund_id: this.emergency_fund_id,
    };
    this.setCurrentPlantInformation(current_plan);
    this.view.$emit('nextStep');
  }
}
